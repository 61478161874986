import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

if (SENTRY_DSN !== "") {
    Sentry.init({
        dsn: SENTRY_DSN,
        release: SENTRY_RELEASE,
        environment: SENTRY_ENVIRONMENT,
        sendDefaultPii: true,
        integrations: [
            new Integrations.BrowserTracing(),
        ],
        tracesSampleRate: 1.0,
        // beforeSend(event, hint) {
        //     if (event.exception) {
        //         Sentry.showReportDialog({
        //             eventId: event.event_id,
        //             user: {
        //                 email: ((USER_EMAIL !== undefined) ? USER_EMAIL : ''),
        //                 name: ((USER_FULL_NAME !== undefined) ? USER_FULL_NAME : ''),
        //             },
        //         });
        //     }
        //     return event;
        // },
    });
}

if (USER_ID !== undefined) {
    Sentry.setUser({ id: USER_ID });
}

if (USER_EMAIL !== undefined) {
    Sentry.setUser({ email: USER_EMAIL });
}

if (USER_USERNAME !== undefined) {
    Sentry.setUser({ username: USER_USERNAME });
}
